import React from "react";
import PropTypes, { oneOfType } from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import ProductDisplayGrid from "../components/ProductDisplayGrid";
import FullWidthImage from "../components/FullWidthImage";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import ProdCard from "../components/ProdCard";
import Gallery from "../components/Gallery";
import Breadcrumbs from "../components/Breadcrumbs"
// eslint-disable-next-line
export const ProductDetailPageTemplate = ({
  image,
  title,
  category,
  product,
  configuration_image,
  feature_text,
  feature,
  technical_specification_image,
  related
}) => {
  const heroImage = getImage(image) || image;

  return (
    <div className="content">
      <FullWidthImage img={heroImage} title={title}/>
      <section className="section section--gradient"> 
      <Breadcrumbs
            crumbs={[
              { link: '/', label: 'Home' },
              { label: 'Products', link: '/products' },
              { label: `${category}`, link: `/products/browse?category=${category}` },
              { label: `${title}` },
            ]}
          /> 
        <div className="container">
          <div className="section">
            <div className="tile is-ancestor">
              <div className="tile is-6">
                <Gallery props={product.blurbs}/>
              </div>
              <div className="tile is-parent is-6">
              {configuration_image && (
                <div class="tile is-child box">
                <p class='subtitle'>Opening Configuration</p>
                <PreviewCompatibleImage imageInfo={configuration_image} alt="Configuration"/>
              </div>
              )}
              </div>
            </div>
            </div>
            <div class="section">
            <div class="columns">
              <div class="column is-12">
                <h3>Product Features</h3>
                <bv/>
                <p>{feature_text}</p>
              </div>
            </div>     
            <div className="columns is-multiline">
              {feature?.blurbs && feature?.blurbs.map((item)=>(
                <div className="column is-6"  style={{display:"inline-block"}}>
                   <div class="columns is-1">
                        <div class="column is-narrow">
                          <p class="image is-64x64 ">
                            <PreviewCompatibleImage imageInfo={item.image} alt=""/>
                          </p>
                        </div>
                        <div class="column" style={{float:"left"}}>
                          <p>
                            <strong>{item.title}</strong>
                            <br/>
                            {item.text}
                          </p>
                        </div>
                    </div>
                </div>
              ))}
            </div>
            </div>
            {technical_specification_image && (
            <div class="section">
              <h3>Technical Specification</h3>
              <div>
                <PreviewCompatibleImage imageInfo={technical_specification_image} alt="Technical Specification"/>
              </div>
            </div>)}
          {related?.blurbs && (
          <div class="section">
              <h1 class="has-text-weight-semibold is-size-2">Related Products</h1>
              <ProdCard gridItems={related.blurbs}/>
          </div>
          )}

        </div>
      </section>
    </div>
  );
};

ProductDetailPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  category: PropTypes.string,
  product: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  configuration_image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  feature_text: PropTypes.string,
  feature: PropTypes.shape({
    blurbs: PropTypes.array
  }),
  technical_specification_image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  related: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const ProductDetailPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ProductDetailPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        category={frontmatter.category}
        product={frontmatter.product}
        configuration_image={frontmatter.configuration_image}
        feature_text={frontmatter.feature_text}
        feature={frontmatter.feature}
        technical_specification_image={frontmatter.technical_specification_image}
        related={frontmatter.related}
      />
    </Layout>
  );
};

ProductDetailPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ProductDetailPage;

export const productDetailPageQuery = graphql`
  query ProductDetailPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        category
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        product {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }
        configuration_image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: CONSTRAINED)
          }
        }
        feature_text
        feature {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(quality: 64, layout: CONSTRAINED)
              }
            }
            title
            text  
          }
        }
        technical_specification_image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: CONSTRAINED)
          }
        }
        related {
          blurbs {
            key
            image {
              childImageSharp {
                gatsbyImageData(height:240, quality: 64, layout: CONSTRAINED)
              }
            }
            text
          }
        }
      }
    }  
  }
`